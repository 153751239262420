@media print {
  body {
    font-size: x-small !important;
    color: blue !important;
  }
  .row {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }

  .col-xl-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .col-sm-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .col-md-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .print {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
