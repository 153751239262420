// colors

$header-dark-theme-bg : #003333;
$dropdown-dark-theme-bg: #057575;
$dropdown-hover-dark-theme-bg: #003A3A;
$sidebar-dark-theme-bg: #002C2B;
$card-dark-theme-bg : #004c4c;
$white: white;
$offwhite: #bdbdbd;
$table-scroll:rgba(0, 44, 43, 0.7);
$table-background:#063a3a;
$btn-nexion-background-hover:#016362;
$btn-nexion-color-hover:#5b626b;